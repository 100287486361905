import * as Base from '../../../components/base';
import * as Styled from './styled';

import React, { useCallback, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Accordion from './Accordion';
import ShowMore from '../../../components/ShowMore';
import galleryImg from '../../../images/gallery.png';
import { useIntl } from 'gatsby-plugin-intl';

const SectionTwo = () => {
  const intl = useIntl();
  const [openedBox, setOpenedBox] = useState(2);

  const handleToggleClick = useCallback(
    id => event => {
      event.preventDefault();

      if (openedBox === id) {
        setOpenedBox(null);
      } else {
        setOpenedBox(id);
      }
    },
    [setOpenedBox, openedBox]
  );

  return (
    <StaticQuery
      query={graphql`
        query {
          bg: file(relativePath: { eq: "2_bg.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Styled.BgContainer imageData={data.bg.childImageSharp.fluid}>
          <Base.Container>
            <div
              css={`
                display: flex;
                justify-content: space-between;
                overflow: visible;
                flex-wrap: nowrap;
              `}
            >
              <div
                css={`
                  display: inline-block;
                  height: auto;
                  flex-basis: 100%;

                  @media ${props => props.theme.breakpoints.md} {
                    flex-basis: 40rem;
                    margin-right: 4rem;
                  }
                `}
              >
                <Base.No2 />
                <Styled.HeadingBlock>
                  <Base.Title1>{intl.formatMessage({ id: 'sectionTwo.heading' })}</Base.Title1>
                </Styled.HeadingBlock>
                <Styled.GalleryContainer
                  css={`
                    display: inline-block;
                    margin-bottom: 4rem;

                    @media ${props => props.theme.breakpoints.md} {
                      display: none;
                    }
                  `}
                >
                  <img
                    css={`
                      display: block;
                      height: auto;
                      width: 100%;
                      margin-bottom: 0;
                    `}
                    src={galleryImg}
                  />
                </Styled.GalleryContainer>
                <Styled.LineDarkGray />
                <Styled.BoxContainer>
                  <Accordion
                    isOpen={openedBox === 1}
                    setOpen={handleToggleClick(1)}
                    title={intl.formatMessage({ id: 'sectionTwo.subHeadingOne' })}
                  >
                    <ShowMore lines={8} smallDeviceLines={50}>
                      {intl.formatMessage({ id: 'sectionTwo.descriptionOne' })}
                    </ShowMore>
                  </Accordion>
                  <Accordion
                    isOpen={openedBox === 2}
                    setOpen={handleToggleClick(2)}
                    title={intl.formatMessage({ id: 'sectionTwo.subHeadingTwo' })}
                  >
                    <ShowMore lines={8} smallDeviceLines={50}>
                      {intl.formatMessage({ id: 'sectionTwo.descriptionTwo' })}
                    </ShowMore>
                  </Accordion>
                  <Accordion
                    isOpen={openedBox === 3}
                    setOpen={handleToggleClick(3)}
                    title={intl.formatMessage({ id: 'sectionTwo.subHeadingThree' })}
                  >
                    <ShowMore lines={8} smallDeviceLines={50}>
                      {intl.formatMessage({ id: 'sectionTwo.descriptionThree' })}
                    </ShowMore>
                  </Accordion>
                </Styled.BoxContainer>
              </div>
              <Styled.GalleryContainer>
                <img
                  css={`
                    display: block;
                    height: auto;
                    width: 100%;
                  `}
                  src={galleryImg}
                />
              </Styled.GalleryContainer>
            </div>
          </Base.Container>
        </Styled.BgContainer>
      )}
    />
  );
};

export default SectionTwo;
