import * as Base from '../../../components/base';
import styled from 'styled-components';
import React from 'react';
import Img from 'gatsby-image';
import Line from '../../../assets/line-kelp.inline.svg';

/* CONTAINERS */

export const BgContainer = styled(Base.BgContainer)`
  /* padding-bottom: 6.3rem; */
  background-position: 10% 70%;
  padding-top: 0;
  padding-bottom: 0;

  @media ${props => props.theme.breakpoints.sm} {
    text-align: center;
  }
  /* @media ${props => props.theme.breakpoints.lg} {
    padding-top: 12rem;
    padding-bottom: 12rem;
  } */
`;

export const Wrapper = styled(Base.Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  max-width: 120rem;
  width: 100%;
  /* margin: 0 auto; */
  padding-right: 3.8rem;
  padding-left: 3.8rem;
`;

export const Container = styled(Base.Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 7rem;
  padding-bottom: 7rem;
  @media ${props => props.theme.breakpoints.md} {
    margin: 0;
    max-width: 50%;
  }

  @media ${props => props.theme.breakpoints.lg} {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  /* @media ${props => props.theme.breakpoints.xl} {
    max-width: 50%;
  } */
`;

export const NateImage = ({ className, imageData }) => <Img Tag='NateImage' className={className} fluid={imageData} />;

export const NateContainer = styled(NateImage)`
  display: none;

  @media ${props => props.theme.breakpoints.md} {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    max-width: 400px;
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7rem;
  }
  @media ${props => props.theme.breakpoints.lg} {
    margin-top: 12rem;
  }
`;

export const SvgContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const HeadingBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 2.7rem;
  }
`;

export const Description = styled(HeadingBlock)`
  margin-top: 2rem;
`;

/* TEXT */

export const Title2 = styled(Base.Title2)`
  font-size: 3rem;
  line-height: 3.7rem;
  margin-bottom: 1.3rem;
`;

export const Text1 = styled(Base.Text1)`
  font-size: 1.6rem;
  margin-top: 2.7rem;
  margin-bottom: 1rem;
  @media ${props => props.theme.breakpoints.sm} {
    text-align: left;
  }
  @media ${props => props.theme.breakpoints.md} {
    text-align: left;
  }
`;

export const Forester = styled.span`
  color: ${props => props.theme.colors.alto};
  font-weight: 700;
`;

export const Pron = styled.span`
  color: ${props => props.theme.colors.gray};
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: 400;
`;

export const Noun = styled.span`
  color: ${props => props.theme.colors.gray};
  font-weight: 400;
`;

export const Definition = styled.span`
  color: ${props => props.theme.colors.cloud};
  font-weight: 400;
`;

/* SVG LINE */

export const SvgLine = styled(Line)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 10.7rem;
`;

/* BUTTON */

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  justify-content: flex-start;
  /* margin-bottom: 9rem; */

  @media ${props => props.theme.breakpoints.lg} {
    order: 2;
    margin-bottom: 0;
    width: 26rem;
    flex-basis: 26rem;
  }
`;

export const Button = styled(Base.Button)`
  @media ${props => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: space-around;
    padding: 0;
    width: 100%;
    padding: 1rem 0 1.4rem;
  }
`;

export default HeadingBlock;
