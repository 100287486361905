import * as hooks from '../../hooks';

import { useCallback, useEffect, useState } from 'react';

export const useModal = (visible, hide) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(visible);
    }, 200);
  }, [visible]);

  const handleHide = useCallback(() => {
    setIsVisible(false);

    setTimeout(() => {
      hide();
    }, 200);
  }, [hide, setIsVisible]);

  const handleKeydown = useCallback(
    event => {
      if (event.keyCode === 27) {
        handleHide();
      }
    },
    [handleHide]
  );

  const handleCloseClick = useCallback(
    e => {
      e.preventDefault();
      handleHide();
    },
    [handleHide]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [isVisible, handleKeydown, handleCloseClick]);

  hooks.useLockBodyScroll(isVisible);

  return [isVisible, handleCloseClick];
};
