import * as Base from '../../../components/base';
import * as Styled from './styled';

import { AnimatePresence, motion } from 'framer-motion';

import React from 'react';

const Accordion = ({ title, isOpen, setOpen, children, icon }) => {
  return (
    <Styled.Box>
      {icon}
      <Styled.BoxTitle>
        <Base.Title2>{title}</Base.Title2>
        <Styled.BoxToggle href="#" onClick={setOpen}>
          <Styled.PlusToggle opened={isOpen} />
        </Styled.BoxToggle>
      </Styled.BoxTitle>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </Styled.Box>
  );
};

export default Accordion;
