import * as Base from '../../../components/base';

import CaretDownSvg from '../../../assets/caret-down.inline.svg';
import LineDarkGraySvg from '../../../assets/line-dark-gray.inline.svg';
import React from 'react';
import styled from 'styled-components';

export const BgContainer = styled(Base.BgContainer)`
  padding-bottom: 6.3rem;
  margin-top: -0.1rem;

  @media ${props => props.theme.breakpoints.md} {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
`;

export const HeadingBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 2.7rem;
  }
`;

export const Text1 = styled.div`
  color: ${props => props.theme.colors.cloud};
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: ${props => props.theme.fonts.sansSerif};
`;

export const BoxContainer = styled.section`
  display: block;
`;

export const Box = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
`;

export const BoxTitle = styled.a`
  display: inline-block;
  height: auto;
  width: 100%;
  margin-bottom: 2rem;
`;

export const BoxDescription = styled(Base.Text2)`
  padding-top: 3rem;

  @media ${props => props.theme.breakpoints.md} {
    padding-top: 1.2rem;
  }
`;

export const BoxToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.7rem;
  margin-right: 2rem;
  float: left;
`;

export const LineDarkGray = styled(LineDarkGraySvg)`
  display: block;
  width: 10.8rem;
  height: auto;
  margin-bottom: 4rem;
`;

export const CaretToggle = styled(CaretDownSvg)`
  width: auto;
  transform: rotateY(0deg) rotate(${props => (props.opened ? '180deg' : '0deg')});
  transition: transform 0.5s;
`;

export const GalleryContainer = styled.div`
  display: none;
  height: auto;
  flex-basis: auto;
  flex-grow: 2;
  position: relative;

  @media ${props => props.theme.breakpoints.md} {
    display: inline-block;
  }
`;

export default HeadingBlock;
