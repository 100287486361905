import React, { memo, useEffect } from 'react';

import { createPortal } from 'react-dom';
import usePortal from '../hooks/usePortal';

const Portal = ({ children }) => {
  const target = usePortal();

  return typeof document !== undefined && target ? createPortal(children, target) : null;
};

export default memo(Portal);
