import X from '../../assets/x.svg';
import modalBg from '../../assets/modal-bg.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9600;
  overflow: auto;
  transform: translated3d(0, 0, 0);

  @media ${props => props.theme.breakpoints.md} {
    padding-bottom: 20rem;
  }
`;

export const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  color: ${props => props.theme.colors.rangoonGreen};
  height: 100%;

  @media ${props => props.theme.breakpoints.md} {
    position: relative;
    max-width: 81rem;
    margin: 11.5rem auto;
    height: auto;
    overflow: hidden;
    border-image-source: url(${modalBg});
    border-image-slice: 18;
    border-width: 2rem;
    border-style: solid;
  }
`;

export const Content = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 3.2rem;
  background-color: ${props => props.theme.colors.kidnapper};

  @media ${props => props.theme.breakpoints.md} {
    padding: 2rem 1rem;
  }
`;

export const MaskContainer = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  transform: translated3d(0, 0, 0);
  z-index: 9500;
`;

export const Mask = styled.div`
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

export const CloseLink = styled.a`
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  background-color: transparent;
  background-image: url(${X});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${props => props.theme.breakpoints.md} {
    height: 1.7rem;
    width: 1.7rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 3rem;
    color: ${props => props.theme.colors.rangoonGreen};
  }
`;
