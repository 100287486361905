import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '../assets/arrow-right-link.inline.svg';
import { useIntl } from 'gatsby-plugin-intl';

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.meteor};
  font-size: 1.4rem;
  font-family: Barlow, sans-serif;
  font-weight: bold;
  margin-bottom: 5rem;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.5rem;
`;

const Link = ({ url }) => {
  const intl = useIntl();

  return (
    <StyledLink rel='noopener noreferrer' target='_blank' href={url}>
      {intl.formatMessage({ id: 'website' })} <StyledArrowRightIcon />
    </StyledLink>
  );
};

export default Link;
