import * as Base from '../../../components/base';
import * as Styled from './styled';

import { AnimatePresence, motion } from 'framer-motion';

import React from 'react';
import ShowMore from '../../../components/ShowMore';

const Accordion = ({ title, isOpen, setOpen, children, icon }) => {
  return (
    <Styled.Box>
      {icon}
      <Styled.BoxTitle href="#" onClick={setOpen}>
        <Styled.BoxToggle>
          <Styled.CaretToggle opened={isOpen} />
        </Styled.BoxToggle>
        <Base.Title2>{title}</Base.Title2>
      </Styled.BoxTitle>
      {isOpen && <Base.Text2>{children}</Base.Text2>}
    </Styled.Box>
  );
};

export default Accordion;
