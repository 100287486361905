import * as Base from '../../../components/base';
import * as Styled from './styled';

import React, { useCallback, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Accordion from './Accordion';
import ShowMore from '../../../components/ShowMore';
import theme from '../../../components/theme';
import { useIntl } from 'gatsby-plugin-intl';
import { useMediaQuery } from 'react-responsive';

const SectionOne = () => {
  const intl = useIntl();

  const [openedBox, setOpenedBox] = useState(1);
  const isWiderScreen = useMediaQuery({ query: theme.breakpoints.md });

  const handleToggleClick = useCallback(
    id => event => {
      event.preventDefault();

      if (openedBox === id) {
        setOpenedBox(null);
      } else {
        setOpenedBox(id);
      }
    },
    [setOpenedBox, openedBox]
  );

  const handleDescriptionShowMoreClick = useCallback(isExpanded => {
    setDescriptionExpanded(isExpanded);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          bg: file(relativePath: { eq: "1_bg.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Styled.BgContainer imageData={data.bg.childImageSharp.fluid}>
          <Base.Container>
            <Base.No1 />
            <Styled.HeadingBlock>
              <Styled.Title1>{intl.formatMessage({ id: 'sectionOne.heading' })}</Styled.Title1>
            </Styled.HeadingBlock>
            <Styled.LineLightGray />
            <Styled.Description>
              <ShowMore smallDeviceLines={6} lines={6}>
                {intl.formatMessage({ id: 'sectionOne.description' })}
              </ShowMore>
            </Styled.Description>
            <Styled.BoxContainer>
              <Accordion
                isOpen={isWiderScreen || openedBox === 1}
                setOpen={handleToggleClick(1)}
                title={intl.formatMessage({ id: 'sectionOne.subHeadingOne' })}
                icon={<Styled.DiceIcon />}
              >
                <Styled.BoxDescription>
                  <ShowMore smallDeviceLines={50} lines={10}>
                    {intl.formatMessage({ id: 'sectionOne.descriptionOne' })}
                  </ShowMore>
                </Styled.BoxDescription>
              </Accordion>
              <Accordion
                isOpen={isWiderScreen || openedBox === 2}
                setOpen={handleToggleClick(2)}
                title={intl.formatMessage({ id: 'sectionOne.subHeadingTwo' })}
                icon={<Styled.SeedIcon />}
              >
                <Styled.BoxDescription>
                  <ShowMore smallDeviceLines={50} lines={10}>
                    {intl.formatMessage({ id: 'sectionOne.descriptionTwo' })}
                  </ShowMore>
                </Styled.BoxDescription>
              </Accordion>
            </Styled.BoxContainer>
          </Base.Container>
        </Styled.BgContainer>
      )}
    />
  );
};

export default SectionOne;
