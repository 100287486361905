import React, { useCallback, useState } from 'react';

import Contact from './Contact';
import Layout from '../../components/Layout';
import Modal from '../../components/Modal';
import SEO from '../../components/seo';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionFour from './SectionFour';
import OurMotivation from './OurMotivation';
import Top from './Top';
import { canUseDOM } from '../../helpers/utils';

const IndexPage = () => {
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);

  const hide = useCallback(() => {
    setIsContactModalVisible(false);
  }, [setIsContactModalVisible]);

  const show = useCallback(() => {
    setIsContactModalVisible(true);
  }, [setIsContactModalVisible]);

  return (
    <Layout>
      <SEO title='Home' />
      {canUseDOM ? (
        <Modal visible={isContactModalVisible} hide={hide} title='Contact'>
          <Contact />
        </Modal>
      ) : null}
      <Top showContactModal={show} />
      <SectionOne />
      <SectionTwo />
      <SectionFour showContactModal={show} />
      <OurMotivation />
    </Layout>
  );
};

export default IndexPage;
