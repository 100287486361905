import * as Base from '../../../components/base';
import * as Styled from './styled';

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import ShowMore from '../../../components/ShowMore';
import Link from '../../../components/Link';

const OurMotivation = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          bg: file(relativePath: { eq: "5_bg.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Styled.BgContainer imageData={data.bg.childImageSharp.fluid}>
          <Base.Container>
            <Styled.Top>
              <Styled.HeadingBlock>
                <Styled.Title2>{intl.formatMessage({ id: 'ourMotivation.heading' })}</Styled.Title2>
                <Styled.Title1>
                  {intl.formatMessage({
                    id: 'ourMotivation.subHeading',
                  })}
                </Styled.Title1>
              </Styled.HeadingBlock>
              <Styled.Description>
                <ShowMore smallDeviceLines={6} lines={8}>
                  {intl.formatMessage({
                    id: 'ourMotivation.description',
                  })}
                </ShowMore>
              </Styled.Description>
              <Styled.LineGray />
            </Styled.Top>
            <Styled.MainContainer>
              <Styled.PersonContainer>
                <Styled.NameText>{intl.formatMessage({ id: 'nate.name' })}</Styled.NameText>
                <Styled.RoleText>{intl.formatMessage({ id: 'nate.role' })}</Styled.RoleText>
                <Styled.Text2>
                  <ShowMore smallDeviceLines={3} lines={8}>
                    {intl.formatMessage({ id: 'nate.description' })}
                  </ShowMore>
                </Styled.Text2>
              </Styled.PersonContainer>
              <Styled.PersonContainer>
                <Styled.NameText>{intl.formatMessage({ id: 'robert.name' })}</Styled.NameText>
                <Styled.RoleText>{intl.formatMessage({ id: 'robert.role' })}</Styled.RoleText>
                <Styled.Text2>
                  <ShowMore smallDeviceLines={3} lines={8}>
                    {intl.formatMessage({ id: 'robert.description' })}
                  </ShowMore>
                </Styled.Text2>
              </Styled.PersonContainer>
              <Styled.PersonContainer>
                <Styled.NameText>{intl.formatMessage({ id: 'tytus.name' })}</Styled.NameText>
                <Styled.RoleText>{intl.formatMessage({ id: 'tytus.role' })}</Styled.RoleText>
                <Styled.Text2>
                  <ShowMore smallDeviceLines={3} lines={8}>
                    {intl.formatMessage({ id: 'tytus.description' })}
                  </ShowMore>
                </Styled.Text2>
              </Styled.PersonContainer>
            </Styled.MainContainer>
          </Base.Container>
        </Styled.BgContainer>
      )}
    />
  );
};

export default OurMotivation;
