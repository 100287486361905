import * as Styled from './styled';
import * as hooks from './hooks';

import React, { memo } from 'react';

import Fade from 'react-reveal/Fade';
import Portal from '../Portal';

const Modal = ({ visible, children, hide, title }) => {
  const [isVisible, handleCloseClick] = hooks.useModal(visible, hide);

  return (
    <Portal>
      <Styled.MaskContainer tabIndex="-1" visible={visible}>
        <Fade when={isVisible} appear duration={100}>
          <Styled.Mask />
        </Fade>
      </Styled.MaskContainer>
      <Styled.Wrapper role="dialog" tabIndex="-2" visible={visible}>
        <Fade collapse bottom when={isVisible} duration={100}>
          <Styled.Container role="document">
            <Styled.Content>
              <Styled.HeaderContainer>
                <h2>{title}</h2>
                <Styled.CloseLink href="#" onClick={handleCloseClick} />
              </Styled.HeaderContainer>
              {children}
            </Styled.Content>
          </Styled.Container>
        </Fade>
      </Styled.Wrapper>
    </Portal>
  );
};

export default memo(Modal);
