import * as Base from '../../../components/base';

import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

export const BgContainer = styled(Base.BgContainer)`
  padding-top: 12rem;
  padding-bottom: 0;

  @media ${props => props.theme.breakpoints.md} {
    padding-top: 12rem;
    padding-bottom: 0;
  }
`;

export const Heading = styled(Base.Title1)`
  text-align: center;

  @media ${props => props.theme.breakpoints.lg} {
    font-size: 3.2rem;
    line-height: 3.7rem;
    max-width: 60rem;
  }
`;

export const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${props => props.theme.breakpoints.lg} {
    flex-direction: column;
    height: 42.4rem;
    height: 64rem;
  }
`;

export const LogoBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 6rem;

  @media ${props => props.theme.breakpoints.lg} {
    flex-basis: 26rem;
    width: 26rem;
    margin-right: 10rem;
    margin-bottom: 2.8rem;
    order: 1;
  }
`;

export const HeadingBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 6rem;

  @media ${props => props.theme.breakpoints.lg} {
    order: 3;
    margin-bottom: 7rem;
    margin-top: 8.3rem;
    flex-basis: auto;
    width: calc(100% - 36rem);
  }
`;

export const QuotesBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 6rem;

  @media ${props => props.theme.breakpoints.lg} {
    order: 4;
    margin-bottom: 0;
    width: calc(100% - 36rem);
    flex-basis: auto;
  }
`;

export const ButtonBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  justify-content: space-around;
  margin-bottom: 9rem;

  @media ${props => props.theme.breakpoints.lg} {
    order: 2;
    margin-bottom: 0;
    width: 26rem;
    flex-basis: 26rem;
  }
`;

export const Button = styled(Base.Button)`
  margin: 0 auto;

  @media ${props => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: space-around;
    padding: 0;
    width: 100%;
    padding: 1rem 0 1.4rem;
  }
`;

export const StyledImg = styled(Img)`
  max-width: 24rem;
  margin: 0 auto;

  @media ${props => props.theme.breakpoints.lg} {
    max-width: 26rem;
  }
`;

export default Button;
