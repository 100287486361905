import * as Base from '../../../components/base';
import * as Styled from './styled';

import React, { useCallback } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Quotes from '../Quotes';
import { useIntl } from 'gatsby-plugin-intl';

const Top = ({ showContactModal }) => {
  const intl = useIntl();

  const handleContactButtonClick = useCallback(() => {
    showContactModal();
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "fp_logo_v.png" }) {
            childImageSharp {
              fluid(maxWidth: 260) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desktop: file(relativePath: { eq: "top_bg_desktop.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Styled.BgContainer imageData={data.desktop.childImageSharp.fluid}>
          <Base.Container>
            <Styled.BlocksContainer>
              <Styled.LogoBlock>
                <Styled.StyledImg fluid={data.logo.childImageSharp.fluid} />
              </Styled.LogoBlock>
              <Styled.HeadingBlock>
                <Styled.Heading>{intl.formatMessage({ id: 'top.description' })}</Styled.Heading>
              </Styled.HeadingBlock>
              <Styled.QuotesBlock>
                <Quotes />
              </Styled.QuotesBlock>
              <Styled.ButtonBlock>
                <Styled.Button onClick={handleContactButtonClick}>
                  {intl.formatMessage({ id: 'letsPlay' })}
                </Styled.Button>
              </Styled.ButtonBlock>
            </Styled.BlocksContainer>
          </Base.Container>
        </Styled.BgContainer>
      )}
    />
  );
};

export default Top;
