import * as Base from '../../../components/base';
import styled from 'styled-components';
import Line1 from '../../../assets/line-kelp.inline.svg';
import React from 'react';

/* CONTAINERS */

export const BgContainer = styled(Base.BgContainer)`
  padding-bottom: 6.3rem;
  background-position: 54% 70%;

  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
`;

export const HeadingBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 2.7rem;
  }

  @media ${props => props.theme.breakpoints.lg} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
  }
`;

export const Top = styled(Base.Container)`
  padding: 0;
  @media ${props => props.theme.breakpoints.lg} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 4rem;
  }
`;

export const MainContainer = styled(Base.Container)`
  padding-left: 0;
  padding-right: 0;

  @media ${props => props.theme.breakpoints.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PersonContainer = styled.div`
  width: 100%;
  text-align: left;

  @media ${props => props.theme.breakpoints.md} {
    max-width: 30%;
  }
`;

/* HEADINGS AND TEXT */

export const Title1 = styled(Base.Title1)`
  font-size: 4.5rem;
  line-height: 5rem;

  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
  @media ${props => props.theme.breakpoints.lg} {
    text-align: left;
  }
`;

export const Title2 = styled(Base.Title2)`
  font-size: 3.2rem;
  line-height: 3.7rem;
  margin-bottom: 1.3rem;

  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
`;

export const Text1 = styled(Base.Text1)`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  line-height: 2.2rem;
  white-space: pre-line;
`;

export const Text2 = styled(Text1)`
  color: ${props => props.theme.colors.gray};
  font-size: 1.4rem;
  margin-bottom: 2rem;
  line-height: 1.8rem;
  white-space: pre-line;

  @media ${props => props.theme.breakpoints.md} {
    text-align: left;
  }
`;

export const Description = styled(Text1)`
  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
  @media ${props => props.theme.breakpoints.lg} {
    flex: 1;
    text-align: left;
    max-width: 50%;
  }
`;

export const NameText = styled(Base.Text1)`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.sansSerif};
  font-size: 3rem;
  margin-bottom: 0.2rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 0.7rem;
  }
`;

export const RoleText = styled(Base.Text2)`
  color: ${props => props.theme.colors.kidnapper};
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const LearnMore = styled(Text2)`
  color: ${props => props.theme.colors.cloud};
  margin-bottom: 0.7rem;
`;

/* SVG LINE */

export const LineGray = styled(Line1)`
  margin-top: 1rem;
  margin-bottom: 4rem;
  @media ${props => props.theme.breakpoints.lg} {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
`;

export default HeadingBlock;
